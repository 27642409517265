<template>
  <v-data-table
    :headers="headers"
    :items="helpDesks.items"
    :options.sync="options"
    @update:options="updateOptions"
    :server-items-length="helpDesks.count"
    :loading="loading"
    :no-data-text="$t('messages.emptyState', { entity: $tc('models.helpDesk.entity') })"
    :no-results-text="$t('messages.emptyState', { entity: $tc('models.helpDesk.entity') })"
    flat
    style="pointer"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn icon small @click="editHelpDesk(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon small @click="deleteHelpDesk(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>

export default ({
  name: 'helpDesksTable',
  data() {
    return {
      expanded: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: ['false'],
        mustSort: ['true'],
        multiSort: false
      },
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.helpDesk.attributes.name'),
          value: 'name'
        },
        {
          text: this.$t('models.helpDesk.attributes.link'),
          value: 'link'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px',
          align: this.showActions ? '' : ' d-none'
        }
      ]
    };
  },
  props: {
    helpDesks: {
      type: Object,
      required: false
    },
    tableName: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    showActions: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateOptions(options) {
      this.$emit('updateOptions', options);
    },
    deleteHelpDesk(item) {
      this.$emit('deleteHelpDesk', item);
    },
    editHelpDesk(item) {
      this.$emit('editHelpDesk', item);
    }
  }
});
</script>
