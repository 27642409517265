<template>
<v-container fluid class="px-lg-5">
  <div class="col-12">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateHelpDeskModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <help-desks-table
        @editHelpDesk="editHelpDesk"
        @deleteHelpDesk="deleteHelpDesk"
        @updateOptions="updateOptions"
        :helpDesks="helpDesks"
        :loading="loading"
        :showActions="true"
      >
      </help-desks-table>
    </v-card>
  </div>
</v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import helpDesksTable from 'components/helpDesks/Table.vue';

export default {
  components: {helpDesksTable},
  data() {
    return {
      loading: false,
      isModalVisible: false,
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.helpDesk.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          link: {
            type: 'text',
            key: 'link',
            value: '',
            label: this.$t('models.helpDesk.attributes.link'),
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.helpDesks'),
        icon: 'mdi-face-agent'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'HelpDesks'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.helpDesks'),
          to: {name: 'HelpDesks'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.helpDesk.entity', 1)}),
        icon: 'mdi-plus'
      }
    };
  },
  computed: mapGetters(['helpDesks', 'isAdmin']),
  methods: {
    ...mapActions([
      'getHelpDesks',
      'destroyHelpDesk',
      'createHelpDesk',
      'updateHelpDesk'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getHelpDesks(options).then(() => (this.loading = false));
    },
    submitModal(data) {
      let form;
      let request;
      switch (this.modal.behaviour) {
      case 'createHelpDesk':
        request = this.createHelpDesk(data);
        break;
      case 'updateHelpDesk':
        form = {
          helpDeskId: this.modal.id,
          form: data
        };
        request = this.updateHelpDesk(form);
        break;
      }
      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editHelpDesk(data) {
      this.modal.behaviour = 'updateHelpDesk';
      this.modal.title = this.$t('views.helpDesks.updateHelpDesk');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = data[field.key];
      });
      this.modal.id = data.id;
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    openCreateHelpDeskModal() {
      this.modal.resetForm = true;
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.title = this.$t('views.helpDesks.newHelpDesk');
      this._.forEach(this.modal.fields, (field) => {
        this.modal.fields[field.key].value = '';
      });
      this.modal.behaviour = 'createHelpDesk';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteHelpDesk(helpDesk) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: helpDesk.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyHelpDesk(helpDesk.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  }
};
</script>
